import React, {useEffect, useState} from "react";
import {Swiper, SwiperSlide} from "swiper/react";
import { useSelector, useDispatch } from 'react-redux'
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { bannerpopup } from '../store/user'
export default function SwiperBanner() {
    const [banners] = useState([
        {name: 'banner 1', image: '/images/banner/01.png'},
        {name: 'banner 2', image: '/images/banner/01.png'},
        {name: 'banner 3', image: '/images/banner/01.png'},
        {name: 'banner 4', image: '/images/banner/01.png'},
        {name: 'banner 5', image: '/images/banner/01.png'},
        {name: 'banner 6', image: '/images/banner/01.png'},
    ]) || [];

    const {banneritemspup} = useSelector(state => state.user);
    const utl = 'https://100lankip2.com/images/boxdeposit.jpg' // lao
    //const utl = 'https://100lankip2.com/images/bank001.png' //thau
    return (
        <>
       
            <img className="w-100 swiper-image"
                 src={`${utl}`}
           />
       
             
           
        </>
    );
}
