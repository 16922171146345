import React, { useEffect } from 'react';
import {Modal} from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux';
import { initializeSelection, toggleAnimal, toggleAllAnimals } from '../store/animals';

const CustomCheckbox = ({ checked, onChange }) => {
    return (
      <div 
        className="d-flex align-items-center justify-content-center border rounded"
        onClick={onChange}
        style={{ 
          width: '30px', 
          height: '30px', 
          cursor: 'pointer',
          backgroundColor: checked ? '#ffc107' : 'white',
          borderColor: checked ? '#ffc107' : '#dee2e6'
        }}
      >
        {checked && (
          <svg 
            width="20" 
            height="20" 
            viewBox="0 0 24 24" 
            fill="none" 
            stroke="white"
            strokeWidth="2" 
            strokeLinecap="round" 
            strokeLinejoin="round"
          >
            <polyline points="20 6 9 17 4 12" />
          </svg>
        )}
      </div>
    );
  };

  
function LoginModal(props) {
   
    const dispatch = useDispatch();
    const animals = useSelector(state => state.animals.animals);
    const selectedAnimals = useSelector(state => state.animals.selectedAnimals);
  
    // Initialize selection on component mount
    useEffect(() => {
      dispatch(initializeSelection());
    }, [dispatch]);
  
    const handleCheckboxChange = (id) => {
      dispatch(toggleAnimal(id));
    };
  
    const handleSelectAll = () => {
      dispatch(toggleAllAnimals());
    };
  
    const isAllSelected = animals.every(animal => selectedAnimals[animal.id]);
  
    return (
        <Modal {...props} centered className={'login-modal'}>
        
            <Modal.Body className={'contentmodal py-4'}>
            <div className="container mt-4">
          {/* Header */}
          <div className="row mb-4">
            <div className="col">
              <h5 className="h5">ສະຫຼັບ</h5>
            </div>
            <div className="col text-end d-flex justify-content-end align-items-center">
              <h5 className="h5 mb-0 me-1">ເລືອກທັງ​ຫມົດ</h5>
              <CustomCheckbox
                checked={isAllSelected}
                onChange={handleSelectAll}
              />
            </div>
          </div>
    
          {/* Animal Grid */}
          <div className="row g-2">
            {animals.map((animal) => (
              <div key={animal.id} className="col-6">
                <div className="d-flex align-items-center">
                  <div className="form-check me-1">
                  <CustomCheckbox
                      checked={selectedAnimals[animal.id] || false}
                      onChange={() => handleCheckboxChange(animal.id)}
                    />
                  </div>
                  <img 
                    src={animal.image} 
                    alt={`Animal ${animal.id}`} 
                    className="me-1"
                    style={{ width: '38px', height: '38px', objectFit: 'contain' }}
                  />
                  <span className="fs-4">{animal.number}</span>
                </div>
              </div>
            ))}
          </div>
    
          {/* Bottom Buttons */}
          <div className="row mt-4">
            <div className="col d-flex justify-content-between">
              <button className="btn btn-light btn-lg px-4" onClick={props.onHide2} >
                ຍົກເລີກ
              </button>
              <button className="btn btn-warning btn-lg px-4 text-white" onClick={props.onHide} >
                ຕົກລົງ
              </button>
            </div>
          </div>
        </div>
           
                
            </Modal.Body>
        </Modal>
    );
}

export default LoginModal;
