import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import axios from 'axios'
import io from "socket.io-client"
import { APIURL, WEBTOKEN, addCommas } from './config'

const nio = io('https://notifyskylot44.net')

export const memberlogin = createAsyncThunk('appAgent/addData', async (provider) => {
    const data = await axios.post(`${APIURL}/members/login`, provider)
    return {
      item:data.data.item
    }
})

export const memberregister = createAsyncThunk('appAgent/memberregister', async (provider) => {

  try {
    const data = await axios.post(`${APIURL}/members/register`, provider)
    return {
      errcode:0,
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const memberchangpass = createAsyncThunk('appAgent/memberchangpass', async (provider) => {

  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/members/changepass/1`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:'changepass',
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})

export const bookhuay = createAsyncThunk('appAgent/bookhuay', async (provider) => {

  try {
    const data = await axios({
      method: "post",
      url: `${APIURL}/members/bookhuay`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:'changepass',
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})


export const createqrcode = createAsyncThunk('appAgent/createqrcode', async (provider) => {
  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/statement/createqrcode`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:0,
      defaultValues:data.data.item,
      navigate:provider.navigate
    }
  } catch (error) {
    return {
      errcode:error.response.status
    }
  }
})


export const createqrcodedeposit = createAsyncThunk('appAgent/createqrcodedeposit', async (provider) => {
  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/statement/createqrcode`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:0,
      defaultValues:data.data.item,
      amount:data.data.item.amount,
      bankphoto:data.data.item.bankphoto,
      navigate:provider.navigate
    }
  } catch (error) {
    return {
      errcode:error.response.status
    }
  }
})


export const memberwithdraw = createAsyncThunk('appAgent/memberwithdraw', async (provider) => {
  try {

    const data = await axios({
      method: "post",
      url: `${APIURL}/withdraw/memberwithdraw`,
      data:{...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });

    return {
      errcode:0,
      item:data.data.item
    }
  } catch (error) {
    return {
      errcode:error.response.status
    }
  }
})


export const gethistory = createAsyncThunk('appAgent/gethistory', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/statement/betlist`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      item:data.data.items,
      meta:data.data.meta,
      tab:data.data.tab,
    }
  })

  export const getbookhuay = createAsyncThunk('appAgent/getbookhuay', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/getbookhuay`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      item:data.data.item,
    }
  })

  export const getlimithuay = createAsyncThunk('appAgent/getlimithuay', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/getlimithuay`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      limitall:data.data.limitall,
      limittype:data.data.limittype,
    }
  })


  export const gethuaylast = createAsyncThunk('appAgent/gethuaylast', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/huay_sub/huaylastbymember`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items
    }
  })

  export const gethuaymaster = createAsyncThunk('appAgent/gethuaymaster', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/huay_sub/gethuaymaster`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items
    }
  })

  
export const getbalance = createAsyncThunk('appAgent/getbalance', async (provider) => {
  //const data = await axios.get(`${APIURL}/members/balance`, provider)
  try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/balance`,
      
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
})


export const affwithdraw = createAsyncThunk('appAgent/affwithdraw', async (provider) => {
    try{
    const data = await axios({
      method: "get",
      url: `${APIURL}/affiliate/1/withdraw`,
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      errcode:200,
      item:data.data.item
    }
  }catch(error){
    return {
      errcode:error.response.status,
      item:{}
    }
  }
  })

  export const gamelist = createAsyncThunk('appAgent/gamelist', async () => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/huay_sub/huaythai`,
      params:{webtoken:WEBTOKEN},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items,
      result_items:data.data.result,
    }
  })

  export const gamelist2 = createAsyncThunk('appAgent/gamelist2', async () => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/huay_sub/huayhk`,
      params:{webtoken:WEBTOKEN},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items,
      result_items:data.data.result,
    }
  })

  export const bannerlist = createAsyncThunk('appAgent/bannerlist', async () => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/banner/findallweb`,
      params:{webtoken:WEBTOKEN},
    });
    return {
      items:data.data.items
    }
  })

  export const bannerpopup = createAsyncThunk('appAgent/bannerpopup', async (provider) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/banner/findallwebpupop`,
      params:{webtoken:WEBTOKEN, ...provider},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      items:data.data.items
    }
  })
  
  export const getwebinfo = createAsyncThunk('appAgent/getwebinfo', async () => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/agent/getinfo/${WEBTOKEN}`
    });
    return {
      item:data.data.item
    }
  })
  
  export const bankregister = createAsyncThunk('appAgent/bankregister', async () => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/bank/bankregister`,
      params:{webtoken:WEBTOKEN},
    });
    return {
      item:data.data.items
    }
  })
  export const checkqrcode = createAsyncThunk('appAgent/checkqrcode', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/statement/checkqrcode`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        item:data.data.item
      }
    }catch(error){
      return {
        errcode:error.response.status,
        item:{}
      }
    }
  })

  export const withdrawaff = createAsyncThunk('appAgent/withdrawaff', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/affiliate/1/withdraw`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        item:data.data.item
      }
    }catch(error){
      return {
        errcode:error.response.status,
        item:{}
      }
    }
  })

  export const checkaffiliate = createAsyncThunk('appAgent/checkaffiliate', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/affiliate/1/checkaffiliate`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        item:data.data.item
      }
    }catch(error){
      return {
        errcode:error.response.status,
        item:{}
      }
    }
  })


  export const balanceweb = createAsyncThunk('appAgent/balanceweb', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/statement/1/balanceweb`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        items:data.data.items
      }
    }catch(error){
      return {
        errcode:error.response.status,
        items:[]
      }
    }
  })

  
  
  export const checkwithdraw = createAsyncThunk('appAgent/checkwithdraw', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/withdraw/checkwithdraw`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        item:data.data.item
      }
    }catch(error){
      return {
        errcode:error.response.status,
        item:{}
      }
    }
  })

  export const huaybillmember = createAsyncThunk('appAgent/huaybillmember', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/report/huaybillmember`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        items:data.data.items
      }
    }catch(error){
      return {
        errcode:error.response.status,
        items:[]
      }
    }
  })

  export const huayrate = createAsyncThunk('appAgent/huayrate', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/huay_sub/huayrate`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        items:data.data.items,
        item:data.data.item,
      }
    }catch(error){
      return {
        errcode:error.response.status,
        items:[],
        item:{}
      }
    }
  })
  
  export const getaffdata = createAsyncThunk('appAgent/getaffdata', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/members/getaffdata`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        item:data.data.item
      }
    }catch(error){
      return {
        errcode:error.response.status,
        item:{}
      }
    }
  })
  
  export const confirmqrcode = createAsyncThunk('appAgent/confirmqrcode', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/statement/${item.sid}/confirmqrcode`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      item:data.data.item
    }
  })

  export const rejectbill = createAsyncThunk('appAgent/rejectbill', async (item) => {
    try{
      const data = await axios({
        method: "get",
        url: `${APIURL}/members/${item.billid}/rejectbill`,
        params:{webtoken:WEBTOKEN, ...item},
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return {
        errcode:200,
        item:data.data.item
      }
    }catch(error){
      return {
        errcode:error.response.status,
        item:{}
      }
    }
  })

  
  
  export const confirmqrcodepost = createAsyncThunk('appAgent/confirmqrcodepost', async (item) => {
    const data = await axios({
      method: "put",
      url: `${APIURL}/statement/${item.sid}/confirmqrcodepost`,
      data:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      item:data.data.item,
      items_auto:data.data.items_auto
    }
  })
  
  export const rejectqrcodepost = createAsyncThunk('appAgent/rejectqrcodepost', async (item) => {
    const data = await axios({
      method: "put",
      url: `${APIURL}/statement/${item.sid}/rejectqrcodepost`,
      data:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
      item:data.data.item,
      items_auto:data.data.items_auto
    }
  })  

const initialState = {
    idcookie:Math.random(),
    cashbackalert: false,
    commissionalert: false,
    isLogged: false,
    isLoading:false,
    isLoadingLogin:false,
    isLoadingBtn:false,
    isloadinggame:false,
    isloadingbanner:false,
    showReject:false,
    showApprove:false,
    isLoadinghis:false,
    btnLoading:false,
    isLoadingBill:false,
    showPass:false,
    showpop:false,
    isloadingbannerp:false,
    statusbank:1,
    headstep:1,
    proid:null,
    depositstep:1,
    reloadPass:0,
    depositpro:{},
    limitall:[],
    itemshistory:[],
    limittype:{},
    amount:0,
    nologin:'2',
    bonus:'0',
    gwayz:'',
    errcode:0,
    webinfo:{},
    cashback:{},
    commission:{},
    meta:{},
    banneritems:[],
    result_items:[],
    mtimestamp:+ Date.now(),
    item:{balance:0, mobile:'********'},
    steplast:0,
    depositamount:0,
    gameitems:[],
    gameitem:{}, // หวยไทย
    betitems:[],
    deposititems:[],
    bonusitems:[],
    banneritemspup:[],
    gamedetailitems:[],
    headphoto:'',
    bankitems:[],
    bankregis:[],
    accountdeposit:{},
    accountwithdraw:{},
    slipphoto:null,
    slipexten:null,
    affcode:null,
    username:null,
    affitem:{},
    huaythai:{},
    huaylast:[],
    huayresult:[],
    huayhoon:[],
    rateitems:[],
    rateitem:{},
    huayitem:{},
    affwithdraw:{},
    approve:false,
    reject:false,
    profile: {
        bcel: '0861616XXXX',
        mobileNo: '0888888888',
        balance: 888888
    }
}


export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        login: (state) => {
            state.isLogged = true;
        },
        setReject:(state) => {
          state.showReject = false
        },
        setHeadStep: (state, action) => {
            state.headstep = action.payload
          },
          setAffcode: (state, action) => {
            localStorage.setItem('affcode', action.payload)
            state.affcode = action.payload
          },
          setslipexten: (state, action) => {
            state.slipexten = action.payload
          },
          setslipphoto: (state, action) => {
            state.slipphoto = action.payload
          },
          closecashbackalert: (state, action) => {
            state.cashbackalert = false
          },
          closecommissionalert: (state, action) => {
            state.commissionalert = false
          },
          setAmount: (state, action) => {
            state.amount = action.payload
          },
          setStep: (state, action) => {
            state.depositstep = action.payload.step
            state.accountdeposit = action.payload.accountdeposit
            state.accountwithdraw = action.payload.accountwithdraw
          },
          checkmemberlogin: (state) => {
            state.isLogged = localStorage.getItem('access_token')?.length > 0;
        },
        getusername: (state, action) => {
            state.username = localStorage.getItem('username');
        },
        countpupop:(state, action) => {
          //state.totalpop  = localStorage.setItem('totalpopup')
          const total1 = localStorage.setItem('totalpopup')
          const totalnew = parseInt(total1) - 1
          localStorage.setItem('totalpopup', totalnew)
          if(totalnew === 0) {
            state.showpop = false
          }else {
            state.showpop = true
          }
        },
        logout: (state) => {
            state.isLogged = false
            localStorage.removeItem('access_token')
            localStorage.removeItem('username')
            window.location.reload()
        },
    },
    extraReducers: builder => {
        builder
          .addCase(memberlogin.pending, (state) => {
            state.isLoadingLogin = true
            state.item = {}
          })
          .addCase(memberlogin.fulfilled, (state, action) => {
            state.isLoadingLogin = false
            //state.isLogged = true
            state.item = action.payload.item  
            if (action.payload.item.access_token) {
              state.isLogged = true
              localStorage.setItem('access_token', action.payload.item.access_token)
              localStorage.setItem('username', action.payload.item.username)
             
              window.location.assign("/")
            }else{
              //window.location.href = 'https://www.skylot44.com'
            }
            //localStorage.setItem('access_token', action.payload.item.access_token)
            //localStorage.setItem('username', action.payload.item.username)
          })
          .addCase(getbalance.pending, (state) => {
            state.isLoading = true
          })
        
          .addCase(huayrate.pending, (state) => {
            state.isLoading = true
            state.rateitems = []
          })
          .addCase(huayrate.fulfilled, (state, action) => {
            state.isLoading = false
            state.rateitems = action.payload.items
            state.rateitem = action.payload.item
          })

          .addCase(balanceweb.pending, (state) => {
            state.isLoading = true
            state.itemshistory = []
          })
          .addCase(balanceweb.fulfilled, (state, action) => {
            state.isLoading = false
            state.itemshistory = action.payload.items
          })

          
          .addCase(withdrawaff.pending, (state) => {
            state.isLoading = true
          })
          .addCase(withdrawaff.fulfilled, (state, action) => {
            state.isLoading = false
            if(action.payload.errcode === 200) {
              const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
              nio.emit("sendagentdeposit", ndata)
            }
            window.location.reload()
           
          })
          .addCase(checkaffiliate.pending, (state) => {
            state.isLoading = true
            state.affwithdraw = {}

          })
          .addCase(checkaffiliate.fulfilled, (state, action) => {
            state.isLoading = false
            state.affwithdraw = action.payload.item
          })
          .addCase(rejectbill.pending, (state) => {
            state.isLoadingBtn = true
          })
          .addCase(rejectbill.fulfilled, (state, action) => {
            state.isLoadingBtn = false
            if(action.payload.errcode === 405) {
              state.showReject = true
            } else {
              window.location.reload()
            }
          })

          .addCase(memberchangpass.pending, (state) => {
            state.isLoadingBtn = true
            state.showPass = false
          })
          .addCase(memberchangpass.fulfilled, (state, action) => {
            state.isLoadingBtn = false
            if(action.payload.errcode === 403) {
              state.showPass = true
            } else {
              state.reloadPass = 1
            }
          })

          .addCase(huaybillmember.pending, (state) => {
            state.isLoading = true
            state.huayhoon = []
          })
          .addCase(huaybillmember.fulfilled, (state, action) => {
            state.isLoading = false
            state.huayhoon = action.payload.items
          })
          .addCase(getbookhuay.pending, (state) => {
            state.isLoadingBill = true
            state.huayitem = {}
          })
          .addCase(getbookhuay.fulfilled, (state, action) => {
            state.isLoadingBill = false
            state.huayitem = action.payload.item
           
            
          })

          .addCase(gethuaylast.pending, (state) => {
            state.isLoading = true
          })
          .addCase(gethuaylast.fulfilled, (state, action) => {
            state.isLoading = false
              state.huaylast = action.payload.items.filter(x=>x.masternameth !== 'รัฐบาลไทย')
           
          })

          .addCase(gethuaymaster.pending, (state) => {
            state.isLoading = true
            state.huayresult = []
          })
          .addCase(gethuaymaster.fulfilled, (state, action) => {
            state.isLoading = false
              state.huayresult = action.payload.items.filter(x=>x.status == 6)
           
          })

          .addCase(getlimithuay.pending, (state) => {
            //state.isLoading = true
          })
          .addCase(getlimithuay.fulfilled, (state, action) => {
            //state.isLoading = false
            state.limitall = action.payload.limitall
            state.limittype = action.payload.limittype
          })
          .addCase(getbalance.fulfilled, (state,action) => {
            state.isLoading = false
            if(action.payload.errcode === 401) {
                state.isLogged = false;
                localStorage.removeItem('access_token')
                localStorage.removeItem('username')
                //console.log('ddd')
                //window.location.reload()
               
                state.nologin = '1'
            } else {
              
              state.item = {...state.item, ...action.payload.item}
            }
            
          })
          .addCase(getaffdata.fulfilled, (state,action) => {
            state.isLoading = false
            if(action.payload.errcode === 401) {
                state.isLogged = false;
                localStorage.removeItem('access_token')
                localStorage.removeItem('username')
            } else {
              state.affitem = {...state.item, ...action.payload.item}
            }
          })
          .addCase(bannerlist.pending, (state) => {
            state.isloadingbanner = true
          })
          .addCase(bannerlist.fulfilled, (state,action) => {
            state.isloadingbanner = false
            state.banneritems = action.payload.items
          })
          .addCase(bannerpopup.pending, (state) => {
            state.isloadingbannerp = true
            state.showpop = false
          })
          .addCase(bannerpopup.fulfilled, (state,action) => {
            state.isloadingbannerp = false
            if(action.payload.items.length > 0) {
              state.showpop = true
              state.banneritemspup = action.payload.items
            }else{
              state.banneritemspup = []
            }
            
          })
          
          .addCase(getwebinfo.pending, (state) => {
            state.isLoading = true
          })
          .addCase(getwebinfo.fulfilled, (state,action) => {
            state.isLoading = false
            state.webinfo = action.payload.item
          })
          .addCase(gamelist.pending, (state) => {
            state.isLoading = true
          })
          .addCase(gamelist.fulfilled, (state,action) => {
            state.isLoading = false
            state.gameitem = action.payload.items
            state.result_items = action.payload.result_items
          })
          .addCase(gamelist2.pending, (state) => {
            state.isLoading = true
          })
          .addCase(gamelist2.fulfilled, (state,action) => {
            state.isLoading = false
            state.gameitem = action.payload.items
            state.result_items = action.payload.result_items
          })
          .addCase(gethistory.pending, (state) => {
            state.isLoadinghis = true
            state.meta = {total:1}
          })
          .addCase(gethistory.fulfilled, (state,action) => {
            state.isLoadinghis = false
            if(action.payload.tab == 'bet') {
              state.betitems = action.payload.item
            }else{
              state.deposititems = action.payload.item
            }
            
            state.meta = action.payload.meta
          })
          .addCase(memberregister.pending, (state) => {
            state.isLoadingLogin = true
          })
          .addCase(memberregister.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.errcode = action.payload.errcode
            if(action.payload.item.access_token) {
              localStorage.setItem('access_token', action.payload.item.access_token)
              localStorage.setItem('username', action.payload.item.username)
              window.location.assign("/")
            }
          })
          .addCase(bankregister.pending, (state) => {
            state.isLoadingLogin = true
          })
          .addCase(bankregister.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.bankregis = action.payload.item
          })
          .addCase(createqrcode.pending, (state) => {
            state.isLoadingLogin = true
            state.depositstep = 1
            state.slipexten = null
            state.slipphoto = null
          })
          .addCase(createqrcode.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.depositstep = 2
            state.headstep = 3
            state.accountdeposit = {status:0, depositstep:2}

            if(action.payload.errcode === 405) {
              alert('ເວລາຊື້ໝົດແລ້ວ')
              window.location = '/'
            } else {
              if(action.payload.defaultValues.qrcodetype === 1) {
                state.accountdeposit = action.payload.defaultValues
            } else if(action.payload.defaultValues.status === 3){
                if(action.payload.defaultValues.name === 'รัฐบาลไทย') {
                  window.location.href= `/bill/huay-thai/${action.payload.defaultValues.huayitem?.result?.id}`
                } else if(action.payload.defaultValues.name === 'หวยฮ่องกง') {
                  window.location.href= `/bill/huay-hk/${action.payload.defaultValues.huayitem?.result?.id}`
                } else {
                  window.location.href= `/bill/huay-hoon/${action.payload.defaultValues.huayitem?.result?.id}`
                }
                
                
            } else {
              const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
              nio.emit("sendagentdeposit", ndata)
              action.payload.navigate('/lotto/tunsamai/payment')
            }
            }
          })

          .addCase(createqrcodedeposit.pending, (state) => {
            state.isLoadingLogin = true
            state.depositstep = 1
            state.depositamount = 0
          })
          .addCase(createqrcodedeposit.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.depositstep = 2
            state.depositamount = action.payload.amount
            state.bankphoto = action.payload.bankphoto
            state.accountdeposit = {status:0, depositstep:2}
            const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
            nio.emit("sendagentdeposit", ndata)
            action.payload.navigate('/lotto/tunsamai/depositconfirm')
          })

          .addCase(bookhuay.pending, (state) => {
            state.isLoadingLogin = true
            state.steplast = 0
          })
          .addCase(bookhuay.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.steplast = 1

            if(action.payload.errcode === 405) {
              alert('ເວລາຊື້ໝົດແລ້ວ')
              window.location = '/'
            } else if(action.payload.item.approve == 1){
              state.showApprove = true
            } else if(action.payload.item.approve == 0){
              state.showReject = true
            } else {
              state.accountdeposit = action.payload.item
              const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
              nio.emit("sendagentdeposit", ndata)
            }

           
          })
          .addCase(checkqrcode.pending, (state) => {
            state.isLoadingLogin = true
            state.depositstep = 1
          })
          .addCase(checkqrcode.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            if(action.payload.errcode === 401) {
              state.isLogged = false;
              localStorage.removeItem('access_token')
              localStorage.removeItem('username')
            } else {
              state.depositstep = 3
              state.accountdeposit = action.payload.item
            }
          })
          .addCase(affwithdraw.pending, (state) => {
            state.btnLoading = true
          })
          .addCase(affwithdraw.fulfilled, (state,action) => {
            state.btnLoading = false
            state.cashbackalert = true
            state.affitem = {...state.affitem, ...action.payload.item}
          })
          .addCase(checkwithdraw.pending, (state) => {
            state.isLoadingLogin = true
          })
          .addCase(checkwithdraw.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            if(action.payload.errcode === 401) {
              state.isLogged = false;
              localStorage.removeItem('access_token')
              localStorage.removeItem('username')
            } else {
              state.accountwithdraw = action.payload.item
            }
            
          })
          .addCase(confirmqrcode.pending, (state) => {
            state.isLoadingLogin = true
          })
          .addCase(confirmqrcode.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.accountdeposit = action.payload.item
            const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
            nio.emit("sendagentdeposit", ndata)
          })
          .addCase(confirmqrcodepost.pending, (state) => {
            state.isLoadingLogin = true
          })
          .addCase(confirmqrcodepost.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.accountdeposit = action.payload.item
            const checkusername = action.payload.items_auto.find(x=>`${x.username}` === `${localStorage.getItem('username')}`)
            if(checkusername) {
               // alert('ยอดฝากได้รับการยืนยัน')
                state.accountdeposit = { ...action.payload.item, checkfinish:'finish', status:3,depositstep:1}
                state.headstep = 1
                state.depositstep = 1
              
            }else{
              state.accountdeposit = { ...action.payload.item,checkfinish:'finish', status:4, depositstep:1}
              state.headstep = 1
              state.depositstep = 1
              //alert('ไม่พบยอดเงิน')
            }
            const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
            nio.emit("sendagentdeposit", ndata)
          })
          .addCase(rejectqrcodepost.pending, (state) => {
            state.isLoadingLogin = true
          })
          .addCase(rejectqrcodepost.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            state.accountdeposit = {depositstep:1}
            state.headstep = 1
            state.depositstep = 1
            
          })
          .addCase(memberwithdraw.pending, (state) => {
            state.isLoadingLogin = true
            state.errcode = 0
          })
          .addCase(memberwithdraw.fulfilled, (state,action) => {
            state.isLoadingLogin = false
            if(action.payload.errcode == 0){
              state.accountwithdraw = action.payload.item
              const ndata = {depositbet:1, WEBTOKEN, notifyitems:[]}
              nio.emit("sendagentdeposit", ndata)
              window.location.reload()
            }else{
              state.errcode = action.payload.errcode
            }
          })
      }
    })
    
    // Actions
export const {login, setReject, logout,closecashbackalert,closecommissionalert,getusername,setPromotion, setAffcode, setslipexten, setslipphoto, checkmemberlogin, setStep,setBonus, setHeadStep, setAmount, setMtimestamp} = userSlice.actions;
export default userSlice.reducer
