import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import * as _ from 'underscore'
import {searchNumber, addToCartAll, setCartAll} from "../store/tunsamai";
import {setAnimals, selectSelectedAnimals} from "../store/animals";
import {useCallback, useEffect, useState} from "react";
import {NumericFormat} from 'react-number-format';
import {numberWithCommas} from "../utils/math";
import Modal from './Modal'
import ModalSwich from './ModalSwich'
import dayjs from "dayjs";
import { Spinner } from "react-bootstrap";
import { getAllPermutations } from "../store/config"

function TunsamaiMenu({addToCart, showSearch}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const {total, searchResult, cart} = useSelector(state => state.tunsamai);
    const {gameitem, limitall, limittype} = useSelector(state => state.user);

  

    
  const nowDayjs = dayjs();
 
    const navigate = useNavigate()
    useEffect(() => {
        dispatch(searchNumber({q: null}));
    }, [location]);

    const [amount, setAmount] = useState(1000);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [msg, setMsg] = useState('');

    const payment = () => {
        
        const timestampDayjs = dayjs(gameitem?.enddatetime);
        if (timestampDayjs.isBefore(nowDayjs)) {
            setMsg(`ເວລາຊື້ໝົດແລ້ວ.`)
            setShow(true)
            return;
        }
        
        if(total < 1000) {

        } else {
            navigate(`/lotto/tunsamai/summary`)
        }
       
    }
    const setAmountX = (newamount) => {
        //const amluntall = parseInt(amount || 0) + parseInt(newamount)
        setAmount(newamount)
    }
    const [q, setQ] = useState("");
    const [numberTop, setnumberTop] = useState(true);
    const [numberLower, setnumberLower] = useState(false);

    const debounceFn = useCallback(_.debounce(handleDebounceQuery, 100), []);

    function handleDebounceQuery(inputValue) {
        dispatch(searchNumber({q: inputValue}));
    }

    function removeLastTwoChars(str) {
        return str.slice(0, -2);
    }

    const [qs, setQs] = useState("");

    function handleQueryChange(event) {
        setQ(event.target.value);
        const xxx = removeLastTwoChars(event.target.value)
        setQs(xxx)
        debounceFn(event.target.value);
    }

    const checkimg = (q, type) => {
        const bill = [
            {id: 1, image: "01", name: "ປານ້ອຍ", numbers: ['01', '41', '81']},
            {id: 2, image: "02", name: "ຫອຍ", numbers: ['02', '42', '82']},
            {id: 3, image: "03", name: "ຫ່ານ", numbers: ['03', '43', '83']},
            {id: 4, image: "04", name: "ນົກຍູງ", numbers: ['04', '44', '84']},
            {id: 5, image: "05", name: "ສິງ", numbers: ['05', '45', '85']},
            {id: 6, image: "06", name: "ເສືອ", numbers: ['06', '46', '86']},
            {id: 7, image: "07", name: "ຫມູ", numbers: ['07', '47', '87']},
            {id: 8, image: "08", name: "ກະຕ່າຍ", numbers: ['08', '48', '88']},
            {id: 9, image: "09", name: "ຄວາຍ", numbers: ['09', '49', '89']},
            {id: 10, image: "10", name: "ນາກນ້ຳ", numbers: ['10', '50', '90']},
            {id: 11, image: "11", name: "ໝາ", numbers: ['11', '51', '91']},
            {id: 12, image: "12", name: "ມ້າ", numbers: ['12', '52', '92']},
            {id: 13, image: "13", name: "ຊ້າງ", numbers: ['13', '53', '93']},
            {id: 14, image: "14", name: "ແມວບ້ານ", numbers: ['14', '54', '94']},
            {id: 15, image: "15", name: "ຫນູ", numbers: ['15', '55', '95']},
            {id: 16, image: "16", name: "ເຜິ້ງ", numbers: ['16', '56', '96']},
            {id: 17, image: "17", name: "ນົກຍາງ", numbers: ['17', '57', '97']},
            {id: 18, image: "18", name: "ແມວປ່າ", numbers: ['18', '58', '98']},
            {id: 19, image: "19", name: "ກະເບື້ອ", numbers: ['19', '59', '99']},
            {id: 20, image: "20", name: "ຂີ້ເຂັບ", numbers: ['20', '60', '00']},
            {id: 21, image: "21", name: "ນົກແອ່ນ", numbers: ['21', '61']},
            {id: 22, image: "22", name: "ນົກກາງແກ", numbers: ['22', '62']},
            {id: 23, image: "23", name: "ລິງ", numbers: ['23', '63']},
            {id: 24, image: "24", name: "ກົບ", numbers: ['24', '64']},
            {id: 25, image: "25", name: "ແຫຼວ", numbers: ['25', '65']},
            {id: 26, image: "26", name: "ນາກບິນ", numbers: ['26', '66']},
            {id: 27, image: "27", name: "ເຕົ່າ", numbers: ['27', '67']},
            {id: 28, image: "28", name: "ໄກ່", numbers: ['28', '68']},
            {id: 29, image: "29", name: "ອ່ຽນ", numbers: ['29', '69']},
            {id: 30, image: "30", name: "ປາໃຫຍ່", numbers: ['30', '70']},
            {id: 31, image: "31", name: "ກຸ້ງ", numbers: ['31', '71']},
            {id: 32, image: "32", name: "ງູ", numbers: ['32', '72']},
            {id: 33, image: "33", name: "ແມງມຸມ", numbers: ['33', '73']},
            {id: 34, image: "34", name: "ກວາງ", numbers: ['34', '74']},
            {id: 35, image: "35", name: "ແບ້", numbers: ['35', '75']},
            {id: 36, image: "36", name: "ເຫງັນ", numbers: ['36', '76']},
            {id: 37, image: "37", name: "ຫຼິ່ນ", numbers: ['37', '77']},
            {id: 38, image: "38", name: "ເໝັ້ນ", numbers: ['38', '78']},
            {id: 39, image: "39", name: "ກະປູ", numbers: ['39', '79']},
            {id: 40, image: "40", name: "ນົກອິນຊີ", numbers: ['40', '80']},
        ]

        const newnumber = `${q}`
        let nnumber = `${q}`
        if(newnumber.length === 1) {
            nnumber = `0${q}`
        }else if(newnumber.length > 2) {
            nnumber = newnumber.slice(-2)
        }else {
            nnumber = `${q}`
        }

        const data  = _.find(bill, function (data) {
            return data.numbers.includes(nnumber);
        });

        return data?.[type]
    }
    function gettpp (length) {
        var result = "";
        var characters = "0123456789";
        var charactersLength = characters.length;
        for (var i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }
    const huayAuto = () => {
        const alls = q.length
        const numleng = 6 - alls
        const qn = `${gettpp(numleng)}${q}`
        const checkdeplicate = cart.find(x=>`${x.number}` === `${qn}`)
        const checkdeplicatedb = limitall.find(x=>`${x.number}` === `${qn}`)
        if(checkdeplicate || checkdeplicatedb){
            return;
        } else {
            addToCart({list: [{...searchResult, image:checkimg(qn, 'image'), ...{result: [qn]}}], amount: 1000});
        }
       
    }
    const addToCartSelectAll = () => {
        if (!amount) return;
        dispatch(addToCartAll({q, qs, amount, numberTop, numberLower}))
        setQ("");
        //setAmount("");
        dispatch(searchNumber({q: null}));
    }

    const add = () => {
        
        const newnumber = `${q}`
        const lgall = newnumber.length
        let reward = `reward${newnumber.length}`
        let rewardper = `reward${newnumber.length}per`

        const rewardper_limit = limittype[rewardper]
        const reward_limit = limittype[reward]

        const checkdeplicate = cart.find(x=>`${x.number}` === `${newnumber}`)
        const checkdeplicatedb = limitall.find(x=>`${x.number}` === `${newnumber}`)

        const timestampDayjs = dayjs(gameitem?.enddatetime);
        /*
        if (timestampDayjs.isBefore(nowDayjs)) {
            setMsg(`ເວລາຊື້ໝົດແລ້ວ.`)
            setShow(true)
            return;
        }
        */

        if(lgall === 2 && numberLower === false && numberTop === false) {
            setMsg(`ເລືອກ​ 2ໂຕບົນ ,2ໂຕລ່າງ`)
            setShow(true)
            return;
        }

        // หายอดแทงทั้งหมดของประเภทนั้นจากตะกร้า

        const checksum = cart.reduce((total, item)=> {
            const checklg = `${item.number}`
            if(checklg.length === lgall) {
                return total + parseInt(item.amount)
            } else {
                return total
            }
        },0)

        const checksumdb = limitall.reduce((total, item)=> {
            const checklg = `${item.number}`
            if(checklg === newnumber) {
                return total + parseInt(item.amount)
            } else {
                return total
            }
        },0)

        const sumall = parseInt(checksum) + parseInt(amount) + parseInt(checksumdb)

        if(checkdeplicate) {
            setMsg(`ໃສ່ຕົວເລກທີ່ຊໍ້າກັນ.`)
            setShow(true)
            return;
        }
        if(checkdeplicatedb && sumall > rewardper_limit) {
            //const sumall2 = parseInt(checksum) + parseInt(checksumdb)
            //const remain = rewardper_limit - sumall2
            console.log('checkdeplicatedb', checkdeplicatedb)
            console.log('sumall', sumall)
            console.log('rewardper_limit', rewardper_limit)
            setMsg(`ບໍ່ສາມາດຊື້ເລກ ${newnumber} ນີ້ໄດ້ແລ້ວ ເລກເຕັມຈຳນວນ ${rewardper_limit} ກີບແລ້ວ`)
            //ບໍ່ສາມາດຊື້ເລກ 456788  ນີ້ໄດ້ແລ້ວ
 
            setShow(true)
            return;
        }
        console.log('sumall', sumall)
        console.log('reward_limit', reward_limit)
        if(sumall > reward_limit) {
            setMsg(`ບໍ່ສາມາດຊື້ເລກ ${newnumber} ນີ້ໄດ້ແລ້ວ ເລກເຕັມຈຳນວນ ${rewardper_limit} ກີບແລ້ວ`)
            //setMsg(`ເລກ ${lgall} ตัว ຊື້ได้ไม่เกิน ${reward_limit} ກີບ`)
            setShow(true)
            return;
        }

        if(amount > rewardper_limit) {
            setMsg(`ເຈົ້າສາມາດຊື້ໄດ້ບໍ່ເກີນ ${rewardper_limit}  ກີບ`)
            setShow(true)
            return;
        }
        if(amount < 1000) {
            setMsg(`ຂັ້ນຕ່ຳ 1,000 ກີບ`)
            setShow(true)
            return;
        }
        if (showSearch) {
            if (!searchResult || !amount) {
                setMsg(`ໃສ່ຕົວເລກ 1 - 6 ຕົວ`)
                setShow(true)
                return;
            } else {

                if(lgall === 2) {
                    if(numberLower === true && numberTop === true) {
                        const sss = [{...searchResult, type:'2lower', ...{result: [q]}}]
                        addToCart({list: sss, amount: amount});
                        const sss2 = [{...searchResult, type:'2top', ...{result: [q]}}]
                        addToCart({list: sss2, amount: amount});
                    }

                    if(numberLower === false && numberTop === true) {
                        const sss = [{...searchResult, type:'2top', ...{result: [q]}}]
                        addToCart({list: sss, amount: amount});
                    }

                    if(numberLower === true && numberTop === false) {
                        const sss = [{...searchResult, type:'2lower', ...{result: [q]}}]
                        addToCart({list: sss, amount: amount});
                    }
                   
                } else {
                    addToCart({list: [{...searchResult, ...{result: [q]}}], amount: amount});
                }
            //return;
           
            }
           
        } else {
            if (!amount) return;
            addToCart({amount: amount});
        }

        // clear input
        setQ("");
        //setAmount("");
        dispatch(searchNumber({q: null}));
        navigate('/lotto/tunsamai')
    }

    const [items, setItems] = useState([])
    const showSwich = () => {
        const result = getAllPermutations(q)
        const itemsaa = result.map((x,index)=> ({id:++index,amount ,number:x, imagename:checkimg(x, 'image'),image:`/images/animal/${checkimg(x, 'image')}.png`}))
        dispatch(setAnimals(itemsaa))
        setShow2(true)
        
    }
    const selectedAnimalsList = useSelector(selectSelectedAnimals);
   
    const hidAnimals =()=> {
       console.log('numberTop', numberTop)
       console.log('numberLower', numberLower)
       if(q.length == 2){
       
            if(numberLower === true && numberTop === true) {
            
                const items = selectedAnimalsList.map(x=>({...x,type:'2lower',image:x.imagename}))
                const items2 = selectedAnimalsList.map(x=>({...x,type:'2top',image:x.imagename}))
                const itemsall = [...items, ...items2]
                dispatch(setCartAll(itemsall))
            }

            if(numberLower === false && numberTop === true) {
             
                const items2 = selectedAnimalsList.map(x=>({...x,type:'2top',image:x.imagename}))
                dispatch(setCartAll(items2))
            }

            if(numberLower === true && numberTop === false) {
                const items2 = selectedAnimalsList.map(x=>({...x,type:'2lower',image:x.imagename}))
                dispatch(setCartAll(items2))
            }
           
       
       } else {
        const items = selectedAnimalsList.map(x=>({...x,image:x.imagename}))
        dispatch(setCartAll(items))
        //setShow2(false)
       }
       setQ("");
       //setAmount("");
       dispatch(searchNumber({q: null}));
       setShow2(false)
    }
    
    return (
        <div className={'fixed-bottom'}>
            <div className={'container tansamai-menu-bottom pt-2'} style={{maxWidth: '450px'}}>

                {searchResult && <div className={'d-flex gap-4 mb-2 suggestion-result'} >
                    <img className="suggestion-icon" alt={'result'}
                         src={`${process.env.PUBLIC_URL}/images/animal/${searchResult.image}.png`}/>

                    <div className={'d-flex gap-2'}>
                        {searchResult.numbers.map(number => {
                            return <div key={`${searchResult.name}_${number}`}
                                        className={`suggestion-number`}>
                                {qs}{number}</div>
                        })}
                    </div>
                    
                    <div className={'d-flex gap-1'}><button className="btnselec" onClick={()=> addToCartSelectAll()} >ທັງ​ຫມົດ</button></div>
                    
                    {q && q.length !== 6 && <div className={'d-flex gap-1'}><button onClick={()=> showSwich()} className="btnselec" >ສະຫຼັບ</button></div>}
                   

                </div>}

                {q && q.length === 2 && <div className={'d-flex justify-content-center mb-2 suggestion-result'} >
                    
                   <div className={'d-flex gap-1 pe-3'}><button className={`${numberTop === true ? 'btnselec':'btnselec_normal'}`} onClick={()=> setnumberTop(!numberTop)} >2ໂຕບົນ</button></div>
                    <div className={'d-flex gap-1'}><button className={`${numberLower === true ? 'btnselec':'btnselec_normal'}`} onClick={()=> setnumberLower(!numberLower)} >2ໂຕລ່າງ</button></div>
                  
                   

                </div>}

                {/* search */}
                {showSearch && <div className={'d-flex gap-2 mb-2'}>
                    <div className={'flex-fill custom__textarea input-search'}>
                        <div className={'text-secondary small'}>ໃສ່ຕົວເລກ</div>
                        <input className={'form-control'}
                                       inputMode={'numeric'}
                                        //type="number"
                                        onKeyPress={(event) => {
                                            if (!/[0-9]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                       allowLeadingZeros
                                       thousandSeparator=","
                                       allowNegative={false}
                                    maxLength={6}
                                       onChange={handleQueryChange}
                                       value={q}
                        />
                    </div>

                    <NavLink to={'/lotto/tunsamai/select'} className={`btn__custom btn-tumra`}>
                        <img className="menu-icon" src={`${process.env.PUBLIC_URL}/images/icon/dog.png`} alt={'tumra'}/>
                        <div>ຕາລາ</div>
                    </NavLink>

                    <NavLink to={'/lotto/tunsamai/random'} className={`btn__custom btn-tumra`}>
                        <img className="menu-icon mt-1" src={`${process.env.PUBLIC_URL}/images/icon/random.png`} alt={'random'}/>
                        <div>ສຸ່ມເລກ</div>
                    </NavLink>

                                 {
                                    /*
 <div className={`btn__custom btn-random-number`} onClick={()=>huayAuto()}>
                        <img className="menu-icon mt-1" src={`${process.env.PUBLIC_URL}/images/icon/random.png`} alt={'random'}/>
                        <div>ສຸ່ມເລກ</div>
                    </div>
                                    */
                                 }           
                   
                </div>}
                {/* end search */}

                {/* amount */}
                <div className={'d-flex gap-2 mb-2'}>
                    <div className={'flex-fill'}>
                        <div className={'custom__textarea input-money mb-2'}>
                            <div className={'text-secondary small'}>ຈໍານວນເງິນ</div>
                            <NumericFormat className={'form-control'}
                                           inputMode={'numeric'}
                                           thousandSeparator=","
                                           allowLeadingZeros
                                           allowNegative={false}
                                           
                                           onChange={e => setAmount(e.target.value.replace(/\,/g, ''))}
                                           value={amount}/>
                                          
                        </div>


                        <div className={'d-flex justify-content-around gap-2 flex-row-2 mb-2'}>
                            {[1000, 2000, 5000, 10000].map(number => {
                                return <div className={'btn-money flex-fill'} key={number}
                                            onClick={() => setAmountX(number)}>
                                    {numberWithCommas(number)}
                                </div>
                            })}
                        </div>

                        <div className={'d-flex justify-content-around gap-2 flex-row-2'}>
                            {[20000, 30000, 50000, 100000].map(number => {
                                return <div className={'btn-money flex-fill'} key={number}
                                            onClick={() => setAmount(number)}>
                                    {numberWithCommas(number)}
                                </div>
                            })}
                        </div>

                    </div>
                    <div className={`btn__custom btn-add`} onClick={() => add()}>
                        <img className="menu-icon mb-1" src={`${process.env.PUBLIC_URL}/images/icon/add.png`} alt={'add'}/>
                        <div>ເພີ່ມ</div>
                    </div>
                </div>
                {/* end amount */}


                {/* total */}
                <div className={'d-flex gap-2 total-box align-items-center'}>
                    <div className={'d-flex justify-content-between align-items-center flex-fill px-3'}>
                        <div>
                        ທັງໝົດ
                        </div>
                        <div className={'d-flex align-items-center'}>
                            <span className={'text-primary total me-3'}> {numberWithCommas(total)} </span> ກີບ
                        </div>
                    </div>

                    {/*<NavLink to={'/lotto/tunsamai/summary'} className={'btn__primary btn__payment h-100 text-decoration-none'}> */ }
                    <div className={'btn__primary btn__payment h-100 text-decoration-none'} onClick={()=>payment()}>
                        <img className="menu-icon me-1"
                             src={`${process.env.PUBLIC_URL}/images/icon/payment.png`} alt={'payment'}/>
                        <div> ຊໍາລະເງິນ </div>
                    </div>
                    {/*</NavLink> */ }
                </div>
                {/* end total */}

            </div>

            <Modal show={show} msg={msg} onHide={()=>setShow(false)}  />
            <ModalSwich show={show2} msg={msg} onHide={()=>hidAnimals(false)} onHide2={()=>setShow2(false)}  />
        </div>);
}

export default TunsamaiMenu;
