import React, { useState } from 'react';
import {useDispatch, useSelector} from "react-redux";
import { useNavigate } from 'react-router-dom';
import {addToCartRx} from "../../store/tunsamai";
import {NumericFormat} from 'react-number-format';
import * as _ from "underscore";
const NumberGame = () => {
    const dispatch = useDispatch();
    const navagate = useNavigate();
    const {list} = useSelector(state => state.tunsamai);

    const [selectedNumber, setSelectedNumber] = useState(6);
    const [userNumbers, setUserNumbers] = useState(Array(6).fill(''));
    const [totalNumbers, setTotalNumbers] = useState(10);
    const [totalAmount, setTotalAmount] = useState(1000);
    
    const setAllAmount = (value)=> {
        setTotalAmount(value.value)
    }
    const numbers = [ 2, 3, 4, 5, 6];

    const setAllTab =(num)=> {
        
        const numx = Array(num).fill('')
        setUserNumbers(numx)
        setSelectedNumber(num)
       

    }

    function randomNumber(count, numberTypes, numberPosition = []) {
        // ตรวจสอบความถูกต้องของ input
        if (!Number.isInteger(count) || count <= 0) {
            throw new Error('count ต้องเป็นจำนวนเต็มบวก');
        }
        if (!Number.isInteger(numberTypes) || numberTypes < 1 || numberTypes > 6) {
            throw new Error('numberTypes ต้องเป็นตัวเลข 1-6');
        }
        if (!Array.isArray(numberPosition)) {
            throw new Error('numberPosition ต้องเป็น array');
        }
    
        // ตรวจสอบความถูกต้องของ numberPosition
        numberPosition.forEach(pos => {
            if (!Number.isInteger(pos.position) || pos.position < 1 || pos.position > numberTypes) {
                throw new Error(`position ต้องเป็นตัวเลข 1-${numberTypes}`);
            }
            if (!Number.isInteger(pos.fixnumber) || pos.fixnumber < 0 || pos.fixnumber > 9) {
                throw new Error('fixnumber ต้องเป็นตัวเลข 0-9');
            }
        });
    
        const result = [];
        
        // สร้างจำนวนตามที่ต้องการ
        for (let i = 0; i < count; i++) {
            let numberStr = '';
            
            // สร้างเลขตามจำนวนหลักของ numberTypes
            for (let position = 1; position <= numberTypes; position++) {
                // ตรวจสอบว่าตำแหน่งนี้มีการกำหนดค่าไว้หรือไม่
                const fixedNumber = numberPosition.find(pos => pos.position === position);
                
                if (fixedNumber) {
                    numberStr += fixedNumber.fixnumber;
                } else {
                    // สุ่มตัวเลข 0-9
                    numberStr += Math.floor(Math.random() * 10);
                }
            }
            
            let ttx = numberTypes.toString()
            if(ttx =='2') {
                ttx = '2top'
            }
            result.push({
                number: numberStr,
                type: ttx
            });
        }
        
        return result;
    }
    
    const hasNumbers = (str) => /[0-9]/.test(str);

    // Handle number input change
    const handleNumberChange = (index, value) => {
      const datax = hasNumbers(value)
      if(datax !== false) {
        const newNumbers = [...userNumbers];
        newNumbers[index] = value;
        setUserNumbers(newNumbers);
      }
      
    };

    const checkimg = (q, type) => {
            const bill = [
                {id: 1, image: "01", name: "ປານ້ອຍ", numbers: ['01', '41', '81']},
                {id: 2, image: "02", name: "ຫອຍ", numbers: ['02', '42', '82']},
                {id: 3, image: "03", name: "ຫ່ານ", numbers: ['03', '43', '83']},
                {id: 4, image: "04", name: "ນົກຍູງ", numbers: ['04', '44', '84']},
                {id: 5, image: "05", name: "ສິງ", numbers: ['05', '45', '85']},
                {id: 6, image: "06", name: "ເສືອ", numbers: ['06', '46', '86']},
                {id: 7, image: "07", name: "ຫມູ", numbers: ['07', '47', '87']},
                {id: 8, image: "08", name: "ກະຕ່າຍ", numbers: ['08', '48', '88']},
                {id: 9, image: "09", name: "ຄວາຍ", numbers: ['09', '49', '89']},
                {id: 10, image: "10", name: "ນາກນ້ຳ", numbers: ['10', '50', '90']},
                {id: 11, image: "11", name: "ໝາ", numbers: ['11', '51', '91']},
                {id: 12, image: "12", name: "ມ້າ", numbers: ['12', '52', '92']},
                {id: 13, image: "13", name: "ຊ້າງ", numbers: ['13', '53', '93']},
                {id: 14, image: "14", name: "ແມວບ້ານ", numbers: ['14', '54', '94']},
                {id: 15, image: "15", name: "ຫນູ", numbers: ['15', '55', '95']},
                {id: 16, image: "16", name: "ເຜິ້ງ", numbers: ['16', '56', '96']},
                {id: 17, image: "17", name: "ນົກຍາງ", numbers: ['17', '57', '97']},
                {id: 18, image: "18", name: "ແມວປ່າ", numbers: ['18', '58', '98']},
                {id: 19, image: "19", name: "ກະເບື້ອ", numbers: ['19', '59', '99']},
                {id: 20, image: "20", name: "ຂີ້ເຂັບ", numbers: ['20', '60', '00']},
                {id: 21, image: "21", name: "ນົກແອ່ນ", numbers: ['21', '61']},
                {id: 22, image: "22", name: "ນົກກາງແກ", numbers: ['22', '62']},
                {id: 23, image: "23", name: "ລິງ", numbers: ['23', '63']},
                {id: 24, image: "24", name: "ກົບ", numbers: ['24', '64']},
                {id: 25, image: "25", name: "ແຫຼວ", numbers: ['25', '65']},
                {id: 26, image: "26", name: "ນາກບິນ", numbers: ['26', '66']},
                {id: 27, image: "27", name: "ເຕົ່າ", numbers: ['27', '67']},
                {id: 28, image: "28", name: "ໄກ່", numbers: ['28', '68']},
                {id: 29, image: "29", name: "ອ່ຽນ", numbers: ['29', '69']},
                {id: 30, image: "30", name: "ປາໃຫຍ່", numbers: ['30', '70']},
                {id: 31, image: "31", name: "ກຸ້ງ", numbers: ['31', '71']},
                {id: 32, image: "32", name: "ງູ", numbers: ['32', '72']},
                {id: 33, image: "33", name: "ແມງມຸມ", numbers: ['33', '73']},
                {id: 34, image: "34", name: "ກວາງ", numbers: ['34', '74']},
                {id: 35, image: "35", name: "ແບ້", numbers: ['35', '75']},
                {id: 36, image: "36", name: "ເຫງັນ", numbers: ['36', '76']},
                {id: 37, image: "37", name: "ຫຼິ່ນ", numbers: ['37', '77']},
                {id: 38, image: "38", name: "ເໝັ້ນ", numbers: ['38', '78']},
                {id: 39, image: "39", name: "ກະປູ", numbers: ['39', '79']},
                {id: 40, image: "40", name: "ນົກອິນຊີ", numbers: ['40', '80']},
            ]
    
            const newnumber = `${q}`
            let nnumber = `${q}`
            if(newnumber.length === 1) {
                nnumber = `0${q}`
            }else if(newnumber.length > 2) {
                nnumber = newnumber.slice(-2)
            }else {
                nnumber = `${q}`
            }
    
            const data  = _.find(bill, function (data) {
                return data.numbers.includes(nnumber);
            });
    
            return data?.[type]
        }

    const isDivisibleBy1000 = (number) => number % 1000 === 0;
    const calAmount =()=> {

        const uitems = userNumbers.reduce((result, list, index)=> {
            let position = ++index
            if(list.length > 0) {
                return [...result, {position, fixnumber:parseInt(list)}]
            } else {
                return [...result]
            }
           
        },[])

        const datax = hasNumbers(parseInt(totalNumbers))
        if(datax !== false) {
            const result = randomNumber(parseInt(totalNumbers),selectedNumber,uitems)

            const check100 = isDivisibleBy1000(parseInt(totalAmount))
           
            if(check100 == true) {
                const items = result.map(x=>({...x, image:checkimg(x.number,'image'),amount:parseInt(totalAmount)}))
                dispatch(addToCartRx(items))
                navagate('/lotto/tunsamai')
            }
        }
      
       
    }
    
  const gotopagec = () => {
    navagate('/lotto/tunsamai')
  }
    return (
      <div className="container py-4">
        <div className="card card-green mx-auto" style={{ maxWidth: '500px' }}>
          <div className="card-body">
            {/* Title */}
            <h2 className="card-title mb-2">ສຸ່ມເລກ</h2>
            
            {/* Number Selection */}
            <div className="mb-4">
              <p className="mb-2">ເລືອກເລກທີ່ຕ້ອງການສຸ່ມ:</p>
              <div className="row g-2">
                {numbers.map((num) => (
                  <div className="col-2" key={num}>
                    <button
                      className={`btn w-100 ${
                        selectedNumber === num 
                          ? 'btn-warning' 
                          : 'btn-light'
                      }`}
                      onClick={() => setAllTab(num)}
                    >
                      {num}
                    </button>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Number Input Display */}
            <div className="mb-2">
              <div className="form-group">
                <label className="mb-2">ໃສ່ຕົວເລກ (ໃສ່ຫຼືບໍ່ໃສ່?):</label>
                <div className="row g-2">
                  {userNumbers.map((num, index) => (
                    <div className="col-2" key={index}>
                       
                      <input
                        type="text"
                        className="form-control text-center"
                        value={num}
                        onChange={(e) => handleNumberChange(index, e.target.value)}
                        maxLength="1"
                        
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
  
            {/* Statistics with Inputs */}
            <div className="row mb-4">
              <div className="col-6">
                <div className="form-group">
                  <label className="mb-2">ຈໍານວນເລກ:</label>
                  <input
                    type="text"
                    className="form-control"
                    value={totalNumbers}
                    onChange={(e) => setTotalNumbers(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-6">
                <div className="form-group">
                  <label className="mb-2">ຈໍານວນເງິນ:</label>
                   <NumericFormat
                        value={totalAmount}
                        onValueChange={setAllAmount}
                        thousandSeparator=","
                        placeholder="ຈໍານວນເງິນ"
                        className={`form-control`}
                    />
                 
                </div>
              </div>
            </div>
  
            {/* Action Buttons */}
            <div className="row g-2">
              <div className="col-6">
                <button className="btn btn-light w-100" onClick={gotopagec}>
                ຍົກເລີກ
                </button>
              </div>
              <div className="col-6">
                <button className="btn btn-warning w-100" onClick={calAmount}>
                ຕົກລົງ
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  
  export default NumberGame;