import React, { useState, useMemo } from 'react';
import {useDispatch, useSelector} from "react-redux";
//import { Card } from "@/components/ui/card";
//import { Trash2 } from 'lucide-react';
import { setTotal } from '../../store/tunsamai';
import { setNumber,updateCart, setType, clearNoAll, setNumberWithDelay, sumTotal, delCart } from '../../store/hk'
import {NumericFormat} from 'react-number-format';
import { CURRENCY } from '../../store/config';
const circleButtonStyle = {
    width: '43px',
    height: '43px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '5px',
    border: 'none',
    transition: 'transform 0.2s',
    cursor: 'pointer'
  };

  const circleButtonStyle2 = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '3px',
    border: 'none',
    transition: 'transform 0.2s',
    cursor: 'pointer'
  };
  
  const LotteryTicket = () => {
    const dispatch = useDispatch();
    const {cart, huaytype, numberno} = useSelector(state => state.hk);
    
    const MIN_BET = 1000;
    const MAX_BET = 500000;
    const [selectedNumbers, setSelectedNumbers] = useState({});
    const [betAmount, setBetAmount] = useState('');
    const [batchError, setBatchError] = useState('');
   
    const [errors, setErrors] = useState({}); // เพิ่ม state สำหรับเก็บ error messages

    const setHuayType = (type) => {
      dispatch(clearNoAll())
        dispatch(setType(type))
    }

const result = Object.entries(selectedNumbers)
  .map(([no, amount]) => ({ no, amount }));


    useMemo(()=>{ 
        const totalBet = Object.values(selectedNumbers).reduce((sum, amount) => 
            sum + (parseFloat(amount) || 0), 0
          );
          const sumtotal = totalBet
          dispatch(setTotal(sumtotal))
          dispatch(sumTotal())
        
    }, [selectedNumbers])

    useMemo(()=>{ 
       
          if(numberno.length == 0) {
            setSelectedNumbers({})
          }
    }, [numberno])
    const numbers = Array.from({ length: 49 }, (_, i) => i + 1);
    const isDivisibleBy1000 = (number) => number % 1000 === 0;
    const validateBetAmount = (amount) => {
        if (amount === 0) return true;
        if (amount < MIN_BET) return `ขั้นต่ำ ${MIN_BET} บาท`;
        if (amount > MAX_BET) return `สูงสุด ${MAX_BET} บาท`;
        
        return '';
      };


  // เพิ่มฟังก์ชันเลือกเลขตามเงื่อนไข
  const selectNumbers = (condition) => {
    if(condition == 'none') {
      dispatch(clearNoAll())
    }
    const newNumbers = { ...selectedNumbers };
    let newam = betAmount || MIN_BET;
    numbers.forEach(num => {
      switch (condition) {
        case 'even':
          if (num % 2 === 0) newNumbers[num] = newNumbers[num] || newam;
          else delete newNumbers[num];
          break;
        case 'odd':
          if (num % 2 !== 0) newNumbers[num] = newNumbers[num] || newam;
          else delete newNumbers[num];
          break;
        case 'all':
          newNumbers[num] = newNumbers[num] || newam;
          break;
        case 'none':
          delete newNumbers[num];
          break;
        default:
          break;
      }
    });

    setSelectedNumbers(newNumbers);
    // เคลียร์ errors เมื่อมีการเลือกใหม่
    setErrors({});
  };

    const toggleNumber = (num) => {
       
        const formattedNum = num < 10 ? `0${num}` : `${num}`;
       const ntxt = `${formattedNum}`

       //dispatch(setNumber(ntxt))
      
       dispatch(setNumberWithDelay({number:ntxt, type:'t1', lg:4}));
     
      setSelectedNumbers(prev => {
        const newNumbers = { ...prev };
        if (num in newNumbers) {
          delete newNumbers[num];
          // ลบ error message เมื่อลบตัวเลข
          //dispatch(setNumberWithDelay({number:ntxt, type:'t1', lg:4}));
          dispatch(setNumberWithDelay({number:ntxt, type:'t1', lg:4}));
          setErrors(prev => {
            const newErrors = { ...prev };
            delete newErrors[num];
            return newErrors;
          });
        } else {
          newNumbers[num] = betAmount || MIN_BET;
        }
        return newNumbers;
      });

    
    };
    
    const handleBetChange = (num, values) => {
      const { value } = values;
      const numValue = parseFloat(value) || 0;

      const itemsall = cart.map(item => 
        item.id === num ? {...item, amount: numValue} : item
    );

    dispatch(updateCart(itemsall))


      
      // เคลียร์ error message เก่า
      setErrors(prev => ({ ...prev, [num]: '' }));
      
      // ตรวจสอบค่าขั้นต่ำและสูงสุด
      if (numValue !== 0) {  // อนุญาตให้ใส่ 0 ได้เพื่อให้สามารถลบค่าออกได้
        if (numValue < MIN_BET) {
          setErrors(prev => ({ ...prev, [num]: `ຕໍ່າສຸດ 1,000 ກີບ` }));
          return;
        }
        if (numValue > MAX_BET) {
          setErrors(prev => ({ ...prev, [num]: `ສູງສຸດ ${MAX_BET} ກີບ` }));
          return;
        }
console.log('num', num)
        if (!isDivisibleBy1000(numValue)) {
          setErrors(prev => ({ ...prev, [num]: `ຈໍາ​ນວນ​ເງິນ​ຕ້ອງ​ເຕັມພັນ` }));
          return;
        }
      }
      
      /*
      4341690601
      setSelectedNumbers(prev => ({
        ...prev,
        [num]: numValue
      }));
      */
    
    };
  
    const handleRemove = (num) => {
      dispatch(delCart(num))
    };

    const handleBatchUpdate = (values) => {
        const amount = parseFloat(values.value) || 0;
        setBetAmount(values.value);
        setBatchError('');
      
        const error = validateBetAmount(amount);
        
        if (error && amount !== 0) {
          setBatchError(error);
          return;
        }
      
        setSelectedNumbers(prev => {
          const newNumbers = {};
          Object.keys(prev).forEach(num => {
            newNumbers[num] = amount;
          });
          return newNumbers;
        });
        setErrors({});

       
      };
  
    return (
      <div className="container-fluid bg-dark py-1">
        <div className="card bg-dark text-white mx-auto" style={{ maxWidth: '900px' }}>
        
         {/* Quick Selection Buttons */}
         
            <h4 className="text-center text-warning mb-2">ເລືອກປະເພດ</h4>
            <div className="mb-4 d-flex justify-content-center gap-2">
            <button 
              className={`btn ${huaytype == '4' ? 'btn-warning':'btn-no'}`}
              //onClick={() => selectNumbers('odd')}
              onClick={() => setHuayType('4')}
            >
              ຕັບປິດ
            </button>
            <button 
              className={`btn ${huaytype == '1' ? 'btn-warning':'btn-no'}`}
              //onClick={() => selectNumbers('odd')}
              onClick={() => setHuayType('1')}
            >
              ເທໃນ + ອອກ
            </button>
            <button 
              className={`btn ${huaytype == '2' ? 'btn-warning':'btn-no'}`}
              onClick={() => setHuayType('2')}
            >
              2 ໂตใນ
            </button>
            <button 
              //className="btn btn-warning"
              className={`btn ${huaytype == '3' ? 'btn-warning':'btn-no'}`}
              onClick={() => setHuayType('3')}
            >
              3 ໂตใນ
            </button>
           
          </div>
          {
            huaytype == '3' &&  <div className="d-flex flex-wrap justify-content-center mb-4">
           <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 0 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 0 && numberno.substring(0, 2)} {numberno.length == 0 && '?'}</button>
                  <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 2 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 2 && numberno.substring(2, 4)} {numberno.length == 0 && '?'}</button>
                  <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 4 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 4 && numberno.substring(4, 6)} {numberno.length == 0 && '?'}</button>
              </div>
          }
          {
            huaytype == '4' &&  <div className="d-flex flex-wrap justify-content-center mb-4">
           <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 0 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 0 && numberno.substring(0, 2)} {numberno.length == 0 && '?'}</button>
                
              </div>
          }

            {
            huaytype == '2' &&  <div className="d-flex flex-wrap justify-content-center mb-4">
            <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 0 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 0 && numberno.substring(0, 2)} {numberno.length == 0 && '?'}</button>
                  <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 2 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 2 && numberno.substring(2, 4)} {numberno.length == 0 && '?'}</button>
                
              </div>
          }

            {
            huaytype == '1' &&  <div className="d-flex flex-wrap justify-content-center mb-4">
            <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 0 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 0 && numberno.substring(0, 2)} {numberno.length == 0 && '?'}</button>
                  <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: '#000',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >+</button>
                  <button
                  style={{
                      ...circleButtonStyle,
                      backgroundColor: numberno.length > 2 ? '#ffc107' : '#ddd',
                      color: 'white' ,
                    }}
                    className="shadow"
                  >{numberno.length > 2 && numberno.substring(2, 4)} {numberno.length == 0 && '?'}</button>
                
              </div>
          }

<div className='text-center pb-2'>
          <button 
              className="btn btn-danger"
              //onClick={() => setHuayType('1')}
              onClick={() => selectNumbers('none')}
            >
              {CURRENCY === 'kip' ? 'ຍົກເລີກ':'เคลียร์'}
               
            </button>
          </div>
         
            {/* Number Grid */}
            <div className="d-flex flex-wrap justify-content-center mb-4">
              {numbers.map(num => (
                <button
                  key={num}
                  onClick={() => toggleNumber(num)}
                  style={{
                    ...circleButtonStyle,
                    backgroundColor: num in selectedNumbers ? '#ffc107' : '#ddd',
                    color: num in selectedNumbers ? 'white' : 'black',
                    transform: `scale(${num in selectedNumbers ? '1.1' : '1'})`
                  }}
                  className="shadow"
                >
                  {num}
                </button>
              ))}
            </div>
            {Object.keys(selectedNumbers).length > 0 &&
             <div className='text-center'>
              {batchError && (
                <div className="text-danger mt-2 small">
                  {batchError}
                </div>
              )}</div>
                }

{cart.length > 55550 &&
                  <div className="bg-secondaryx p-1 rounded mb-1">
                    <div className="row align-items-center">
                      <div className="col-auto">
                       ใส่ยอดเท่ากัน
                      </div>
                      <div className="col">
                      <div className="input-group">
                      <NumericFormat
                    value={betAmount}
                    onValueChange={handleBatchUpdate}
                    thousandSeparator=","
                    placeholder="ใส่จำนวนเงินที่ต้องการให้เท่ากันทุกรายการ"
                    className={`form-control bg-dark text-white ${batchError ? 'border-danger' : ''}`}
                  />
                        <span className="input-group-text bg-dark text-warning">บาท</span>
                      </div>
                     
                    </div>
                      <div className="col-auto">
                     
                      </div>
                    </div>
                  </div>
        }

            {
                cart.length > 0 && (
                    <div>
                        <h5 className="text-warning mb-1 text-center">{cart.length} ລາຍການ</h5>
                        {
                            cart.map((x, num)=> <div key={num} className="bg-secondaryx rounded p-1 mb-1">
                            <div className="row align-items-center">
                              {
                                x.type !== '2lower' &&  <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(0, 2)}
                                </div>
                              </div>
                              }

                            {
                                x.type == '2lower' &&  <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  background:'transparent',
                                  color: 'white'
                                }}>
                                  
                                </div>
                              </div>
                              }
                           {
                               x.type == '2lower' &&  <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#000',
                                  color: 'white'
                                }}>
                                  +
                                </div>
                              </div>
                              }
                              {
                                x.type == '4outside'  &&  <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#000',
                                  color: 'white'
                                }}>
                                  +
                                </div>
                              </div>
                              }
                              {
                                x.type !== '2lower' && <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(2, 4)}
                                </div>
                              </div>
                              }

                              {
                                x.type == '2lower' && <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(0, 2)}
                                </div>
                              </div>
                              }
                              
                              {
                                x.type == '4inside' &&  <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#212529',
                                  color: 'white'
                                }}>
                                  
                                </div>
                              </div>
                              }
                              {
                                x.lg == 6 && <div className="col-auto mc2001">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(4, 6)}
                                </div>
                              </div>
                              }
                            
                              <div className="col">
                              <div className="input-group">
                                <NumericFormat
                                  value={x.amount}
                                  onValueChange={(values) => handleBetChange(num, values)}
                                  thousandSeparator=","
                                  placeholder="จำนวนเงิน"
                                  className={`form-control bg-dark text-white ${errors[num] ? 'border-danger' : ''}`}
                                  allowLeadingZeros={false}
                                  allowNegative={false}
                                />
                                <span className="input-group-text bg-dark text-warning">x {x.rate}</span>
                              </div>
                              {/* แสดง error message */}
                              {errors[num] && (
                                <div className="text-danger mt-1 small">
                                  {errors[num]}
                                </div>
                              )}
                            </div>
                              <div className="col-auto">
                              <div
                               onClick={() => handleRemove(x.id)}
                              style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#dc3545',
                                  color: 'white'
                                }}>
                                  ລຶບ
                                </div>
                               
                              </div>
                            </div>
                          </div>)
                        }
                         
                    </div>
                )
            }
             
            {/* Selected Numbers */}
           
        </div>

      </div>
    );
  };
  
  export default LotteryTicket;