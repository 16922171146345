import { createSlice, createSelector } from '@reduxjs/toolkit';

export const selectSelectedAnimals = createSelector(
    [
      state => state.animals.animals,
      state => state.animals.selectedAnimals
    ],
    (animals, selectedAnimals) => {
      return animals.filter(animal => selectedAnimals[animal.id] === true);
    }
  );

const generateAnimals = () => {
  return Array.from({ length: 30 }, (_, index) => ({
    id: index + 1,
    number: String(Math.floor(Math.random() * 900) + 100),
    image: '/api/placeholder/80/80',
    group: index % 2 === 0 ? 'left' : 'right'
  }));
};

const initialState = {
  animals: [],
  selectedAnimals: {}
};

const animalSelectionSlice = createSlice({
  name: 'animalSelection',
  initialState,
  reducers: {
    initializeSelection: (state) => {
      state.selectedAnimals = state.animals.reduce((acc, animal) => {
        acc[animal.id] = true;
        return acc;
      }, {});
    },
    setAnimals:(state, action) => {
        state.selectedAnimals = action.payload.reduce((acc, animal) => {
            acc[animal.id] = true;
            return acc;
          }, {});
        state.animals = action.payload
    },
    toggleAnimal: (state, action) => {
      const id = action.payload;
      state.selectedAnimals[id] = !state.selectedAnimals[id];
    },
    toggleAllAnimals: (state) => {
      const allSelected = state.animals.every(animal => state.selectedAnimals[animal.id]);
      
      state.animals.forEach(animal => {
        state.selectedAnimals[animal.id] = !allSelected;
      });
    }
  }
});

export const { initializeSelection, toggleAnimal, setAnimals, toggleAllAnimals } = animalSelectionSlice.actions;
export default animalSelectionSlice.reducer;