import {NavLink} from "react-router-dom";
import Logo from "./Logo";

function PageHeader({logo, logoSize, backPath, logOut, goPath, logoutPage}) {
    return (
        <div className={'mb-5'}>
            {backPath &&
                <NavLink className={'btn-back position-absolute'} to={backPath}>
                    <img className="icon-back" src={`${process.env.PUBLIC_URL}/images/icon/arrow_back.png`} alt={'header'}/>
                </NavLink>
            }

           

          
             
            

        </div>
    );
}

export default PageHeader;
