import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { APIURL, WEBTOKEN, addCommas } from './config'
import * as _ from "underscore";
import axios from 'axios'


export const getbillhuay = createAsyncThunk('appAgent/getbillhuay', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/getbillhuay`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
        items:data.data.items,
        userlast:data.data.userlast,
    }
  })

const initialState = {
    list: [
        {id: 1, image: "01", name: "ປານ້ອຍ", numbers: ['01', '41', '81']},
        {id: 2, image: "02", name: "ຫອຍ", numbers: ['02', '42', '82']},
        {id: 3, image: "03", name: "ຫ່ານ", numbers: ['03', '43', '83']},
        {id: 4, image: "04", name: "ນົກຍູງ", numbers: ['04', '44', '84']},
        {id: 5, image: "05", name: "ສິງ", numbers: ['05', '45', '85']},
        {id: 6, image: "06", name: "ເສືອ", numbers: ['06', '46', '86']},
        {id: 7, image: "07", name: "ຫມູ", numbers: ['07', '47', '87']},
        {id: 8, image: "08", name: "ກະຕ່າຍ", numbers: ['08', '48', '88']},
        {id: 9, image: "09", name: "ຄວາຍ", numbers: ['09', '49', '89']},
        {id: 10, image: "10", name: "ນາກນ້ຳ", numbers: ['10', '50', '90']},
        {id: 11, image: "11", name: "ໝາ", numbers: ['11', '51', '91']},
        {id: 12, image: "12", name: "ມ້າ", numbers: ['12', '52', '92']},
        {id: 13, image: "13", name: "ຊ້າງ", numbers: ['13', '53', '93']},
        {id: 14, image: "14", name: "ແມວບ້ານ", numbers: ['14', '54', '94']},
        {id: 15, image: "15", name: "ຫນູ", numbers: ['15', '55', '95']},
        {id: 16, image: "16", name: "ເຜິ້ງ", numbers: ['16', '56', '96']},
        {id: 17, image: "17", name: "ນົກຍາງ", numbers: ['17', '57', '97']},
        {id: 18, image: "18", name: "ແມວປ່າ", numbers: ['18', '58', '98']},
        {id: 19, image: "19", name: "ກະເບື້ອ", numbers: ['19', '59', '99']},
        {id: 20, image: "20", name: "ຂີ້ເຂັບ", numbers: ['20', '60', '00']},
        {id: 21, image: "21", name: "ນົກແອ່ນ", numbers: ['21', '61']},
        {id: 22, image: "22", name: "ນົກກາງແກ", numbers: ['22', '62']},
        {id: 23, image: "23", name: "ລິງ", numbers: ['23', '63']},
        {id: 24, image: "24", name: "ກົບ", numbers: ['24', '64']},
        {id: 25, image: "25", name: "ແຫຼວ", numbers: ['25', '65']},
        {id: 26, image: "26", name: "ນາກບິນ", numbers: ['26', '66']},
        {id: 27, image: "27", name: "ເຕົ່າ", numbers: ['27', '67']},
        {id: 28, image: "28", name: "ໄກ່", numbers: ['28', '68']},
        {id: 29, image: "29", name: "ອ່ຽນ", numbers: ['29', '69']},
        {id: 30, image: "30", name: "ປາໃຫຍ່", numbers: ['30', '70']},
        {id: 31, image: "31", name: "ກຸ້ງ", numbers: ['31', '71']},
        {id: 32, image: "32", name: "ງູ", numbers: ['32', '72']},
        {id: 33, image: "33", name: "ແມງມຸມ", numbers: ['33', '73']},
        {id: 34, image: "34", name: "ກວາງ", numbers: ['34', '74']},
        {id: 35, image: "35", name: "ແບ້", numbers: ['35', '75']},
        {id: 36, image: "36", name: "ເຫງັນ", numbers: ['36', '76']},
        {id: 37, image: "37", name: "ຫຼິ່ນ", numbers: ['37', '77']},
        {id: 38, image: "38", name: "ເໝັ້ນ", numbers: ['38', '78']},
        {id: 39, image: "39", name: "ກະປູ", numbers: ['39', '79']},
        {id: 40, image: "40", name: "ນົກອິນຊີ", numbers: ['40', '80']},
    ],
    cart: [],
    billitems:[],
    items:[],
    checkItems:{},
    userlast:{},
    total: 0,
    searchResult: null
}

export const tunsamaiSlice = createSlice({
    name: 'tunsamai',
    initialState,
    reducers: {
        setTotal:(state, action) => {
            state.total = action.payload
        },
        setItems:(state, action) => {
            const initialSelection = action.payload.reduce((acc, animal) => {
                    acc[animal.id] = true;
                    return acc;
                  }, {});
            state.items = action.payload
            state.checkItems = initialSelection
        },
        setcheckItems:(state, action) => {
            const initialSelection = action.payload.reduce((acc, animal) => {
                    acc[animal.id] = true;
                    return acc;
                  }, {});
            state.items = action.payload
            state.checkItems = initialSelection
        },
        addToCartRx:(state, action) => {
            const newitems = [...state.cart, ...action.payload]
            state.cart = newitems.map((x,index)=>({...x, id:++index}))
            tunsamaiSlice.caseReducers.calculateTotal(state)
        },
        addToCart: (state, action) => {
            let {list, amount} = action.payload;
            const items = list.reduce((result, list) => {
                const name = list.name
                const image = list.image
                const number = list.result.map(x=>({number:x, amount, name, image, type:list.type}))
                return [...result, ...number]
            },[])
            const newitems = [...state.cart, ...items]
            state.cart = newitems.map((x,index)=>({...x, id:++index}))
            tunsamaiSlice.caseReducers.calculateTotal(state)
        },
        removeToCart: (state, action) => {
            let {id} = action.payload;
            const items = state.cart.filter(x=>x.id !== id)
            state.cart = items.map((x,index)=>({...x, id:++index}))
            tunsamaiSlice.caseReducers.calculateTotal(state)
        },
        addToCartAll: (state, action) => {

            let {q, qs, amount, numberLower, numberTop} = action.payload;
            
            const newnumber = `${q}`
            let nnumber = `${q}`
            if(newnumber.length === 1) {
                nnumber = `0${q}`
            }else if(newnumber.length > 2) {
                nnumber = newnumber.slice(-2)
            }else {
                nnumber = `${q}`
            }
       
            const newscc = state.list.reduce((result, list) => {
                const checkdata = list.numbers.includes(nnumber)
                if(checkdata) {
                    if(numberLower === true && numberTop === true && newnumber.length === 2) {
                        const niuetsm  = list.numbers.map(x=>({number:x, amount, name:list.name, image:list.image}))
                        const niuetsm2  = list.numbers.map(x=>({number:x, amount, type:'2lower', name:list.name, image:list.image}))
                        return [...result, ...niuetsm, ...niuetsm2]
                    } else if(numberLower === false && numberTop === true && newnumber.length === 2){
                        const niuetsm  = list.numbers.map(x=>({number:x, amount, name:list.name, image:list.image}))
                        return [...result, ...niuetsm]
                    } else if(numberLower === true && numberTop === false && newnumber.length === 2){
                        const niuetsm  = list.numbers.map(x=>({number:x, amount, type:'2lower', name:list.name, image:list.image}))
                        return [...result, ...niuetsm]
                    } else {
                        const niuetsm  = list.numbers.map(x=>({number:x, amount, name:list.name, image:list.image}))
                        return [...result, ...niuetsm]
                    }
                    
                }else {
                    return [...result]
                }
            } ,[])

            const newsccnew = newscc.map(x=>({...x,number:`${qs}${x.number}` }))
            const newitems = [...state.cart, ...newsccnew]
            state.cart = newitems.map((x,index)=>({...x, id:++index}))
            tunsamaiSlice.caseReducers.calculateTotal(state)
          
        },
        setCartAll:(state, action) => {
            const newitems = [...state.cart, ...action.payload]
            state.cart = newitems.map((x,index)=>({...x, id:++index}))
            tunsamaiSlice.caseReducers.calculateTotal(state)
        },
        calculateTotal: (state) => {
            let total = 0;
            state.cart.map(data => {
                total += Number(data.amount);
            });
            state.total = total;
        },
        searchNumber: (state, action) => {
            let {q} = action.payload;
            const newnumber = `${q}`
            let nnumber = `${q}`
            if(newnumber.length === 1) {
                nnumber = `0${q}`
            }else if(newnumber.length > 2) {
                nnumber = newnumber.slice(-2)
            }else {
                nnumber = `${q}`
            }
            state.searchResult = null;
            state.searchResult = _.find(state.list, function (data) {
                return data.numbers.includes(nnumber)
            });
        },
        clearCart: (state) => {
            state.cart = [];
            state.total = 0;
        }
    },
    extraReducers: builder => {
        builder
          .addCase(getbillhuay.pending, (state) => {
            state.isLoading = true
            state.billitems = []
            state.userlast = {}
          })
          .addCase(getbillhuay.fulfilled, (state, action) => {
            state.isLoading = false
            state.billitems = action.payload.items  
            state.userlast = action.payload.userlast  
          })
        }
})

// Actions
export const {addToCart, addToCartRx, setItems, setTotal, setCartAll, addToCartAll, removeToCart, searchNumber, clearCart} = tunsamaiSlice.actions;
export default tunsamaiSlice.reducer
