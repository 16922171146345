import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import { APIURL, WEBTOKEN, addCommas, CURRENCY } from './config'
import * as _ from "underscore";
import axios from 'axios'

// Constants
const LAST_VIEWED_KEY = 'photox';
//const ONE_HOUR = 60 * 60 * 1000; // 1 hour in milliseconds
const ONE_HOUR = 30 * 60 * 1000; // 5 นาที

// Local Storage Utils
const viewingUtils = {
  setLastViewedTime: () => {
    localStorage.setItem(LAST_VIEWED_KEY, Date.now().toString());
  },
  getLastViewedTime: () => {
    return parseInt(localStorage.getItem(LAST_VIEWED_KEY) || '0');
  },
  canShowSlides: () => {
    const lastViewed = viewingUtils.getLastViewedTime();
    const now = Date.now();
    return (now - lastViewed) > ONE_HOUR;
  },
  checkIfAllViewed: (items) => {
    return items.every(item => !item.show);
  }
};

export const clearNumberDelayed = createAsyncThunk(
    'cart/clearNumberDelayed',
    async () => {
      await new Promise(resolve => setTimeout(resolve, 2000));
      return;
    }
  );

  // สร้าง async thunk สำหรับ delay
export const setNumberWithDelay = createAsyncThunk(
    'cart/setNumberWithDelay',
    async ({number, type, lg}, { dispatch }) => {
      // เพิ่มเข้า cart ก่อน
      dispatch(tunsamaiSlice.actions.addToCart(number));
      
      // รอ 2 วินาที
      await new Promise(resolve => setTimeout(resolve, 100));
      // clear number
      dispatch(tunsamaiSlice.actions.clearNumber(number));
    }
  );

export const getbillhuay = createAsyncThunk('appAgent/getbillhuay', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/members/getbillhuay`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
        items:data.data.items,
        userlast:data.data.userlast,
    }
  })

  export const getBannerPop = createAsyncThunk('appAgent/getBannerPop', async (item) => {
    const data = await axios({
      method: "get",
      url: `${APIURL}/banner/loadphoto`,
      params:{webtoken:WEBTOKEN, ...item},
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    return {
        items:data.data.items
    }
  })

const initialState = {
    cart: [],
    billitems:[],
    total: 0,
    huaytype: '1',
    numberno: '',
    isLoading:false,
    showphoto:false,
    currentPhoto:null,
    banneritems:[
     //{id:1, photo:'https://100lankip2.com/images/banner/d8539d44-baf8-441b-a81d-1961fa27b94c.png',show:true},
     // {id:2, photo:'https://100lankip2.com/images/banner/6823046b-5fbc-4d51-9da6-e518583ea4c4.png',show:true},
     // {id:3, photo:'https://100lankip2.com/images/banner/9bde069a-9a23-48ef-a88a-ea6c91907c27.jpeg',show:true},
    ]
}

export const tunsamaiSlice = createSlice({
    name: 'hk',
    initialState,
    reducers: {
        sumTotal: (state) => {
          const total = state.cart.reduce((total, item) => total + parseInt(item.amount),0)
          state.total = total
        },
        togglePhoto: (state, action) => {
          const photo = state.banneritems.find(item => item.id === action.payload);
          if (photo) {
            photo.show = false;
          }
          state.currentPhoto = null;
              // ถ้าดูครบทุกรูปแล้ว บันทึกเวลา
          if (state.banneritems.every(item => !item.show)) {
            viewingUtils.setLastViewedTime();
          }
        },
        setCurrentPhoto: (state, action) => {
          state.currentPhoto = action.payload;
        },
        showNextPhoto: (state) => {
          //state.currentPhoto = state.banneritems.find(item => item.show) || null;
          if (viewingUtils.canShowSlides()) {
            state.currentPhoto = state.banneritems.find(item => item.show) || null;
          }
        },
        delCart: (state, action) => {
          const items = state.cart.filter((x)=>x.id !== action.payload)
          const total = items.reduce((total, item) => total + parseInt(item.amount),0)
          state.total = total
          state.cart = items
        },
        editCart: (state, action) => {
          const { id, amount } = action.payload
          const items = state.cart.filter((x)=>x.id === id)
          const total = items.reduce((total, item) => total + parseInt(item.amount),0)
          state.total = total
          state.cart = items
        },
        addToCart: (state, action) => {
          //console.log('txt_length')
          let txt_length = `${state.numberno}${action.payload}`
          const txt_old = `${state.numberno}`

          const txt_check = txt_old.slice(-2);
            if(txt_check === action.payload && txt_old.length == 2) {
              txt_length = ``
            }else if(txt_check === action.payload && txt_old.length == 4) {
              txt_length = `${state.numberno}`
            }
          
            let totalnum = 4
            let lg = 4
            let type = '4outside'
            let rate = 90
            if(state.huaytype == '3'){
                totalnum = 6
                lg = 6
                type = '6inside'
                rate = 400
            }
            if(state.huaytype == '2'){
                totalnum = 4
                lg = 4
                type = '4inside'
                rate = 50
            }

            if(state.huaytype == '4'){
              totalnum = 2
              lg = 2
              type = '2lower'
              rate = 41
          }
         
          let minbet = 5
          if(CURRENCY == 'kip') {
            minbet = 1000
          }
             // state.numberno ตรงนี้ถ้าอยากหน่วงไว้ 2 วินาที ค่อยเคลียค่าว่าง ต้องทำไงครับ
            if(txt_length.length == totalnum) {
                const items = [{no:txt_length, type, lg:lg, min:minbet, amount:minbet, rate}, ...state.cart ].map((x, index)=> ({...x, id:index++}))
                state.cart = items
            }
            if(txt_check === action.payload && txt_old.length == 2) {
              //console.log('state.numberno', state.numberno)
              state.numberno = ``
            }else if(txt_check === action.payload && txt_old.length == 4){
              var strFirstThree = state.numberno.substring(0,2);
              state.numberno = `${strFirstThree}`
            }else{
              //console.log('state.numberno', state.numberno)
              state.numberno = `${state.numberno}${action.payload}`
            }
            
           
          },
          clearNumber: (state, action) => {
           
            const txt_length = `${state.numberno}`
            let totalnum = 4
            let lg = 4
            let type = 't1'
            if(state.huaytype == '3'){
                totalnum = 6
                lg = 6
                type = 't3'
            }
            if(state.huaytype == '2'){
                totalnum = 4
                lg = 4
                type = 't2'
            }

            if(state.huaytype == '4'){
              totalnum = 2
              lg = 2
              type = '2lower'
          }
             // state.numberno ตรงนี้ถ้าอยากหน่วงไว้ 2 วินาที ค่อยเคลียค่าว่าง ต้องทำไงครับ
            if(txt_length.length == totalnum) {
               
                state.numberno = ''
              
            }
          },
        setTotal:(state, action) => {
            state.total = action.payload
        },
        clearNoAll:(state, action) => {
          state.numberno = ''
      },
        setType:(state, action) => {
            state.huaytype = action.payload
        },
        setNumber:(state, action) => {
            const txt_length = `${state.numberno}${action.payload}`
            let totalnum = 4
            let lg = 4
            let type = 't1'
            if(state.huaytype == '3'){
                totalnum = 6
                lg = 6
                type = 't3'
            }
            if(state.huaytype == '2'){
                totalnum = 4
                lg = 4
                type = 't2'
            }
             // state.numberno ตรงนี้ถ้าอยากหน่วงไว้ 2 วินาที ค่อยเคลียค่าว่าง ต้องทำไงครับ
            if(txt_length.length == totalnum) {
                //state.cart = [...state.cart, {no:txt_length, type, lg:lg, min:5}]
                // state.numberno ตรงนี้ถ้าอยากหน่วงไว้ 2 วินาที ค่อยเคลียค่าว่าง ต้องทำไงครับ
                //state.numberno = ''
              
            }else {
                state.numberno = `${state.numberno}${action.payload}`
            }
        },
        clearCart: (state) => {
            state.cart = [];
            state.total = 0;
        },
        updateCart: (state, action) => {
          state.cart = action.payload;
          const total = action.payload.reduce((total, item) => total + parseInt(item.amount),0)
          state.total = total
      }
    },
    extraReducers: (builder) => {
        builder.addCase(clearNumberDelayed.fulfilled, (state) => {
          state.numberno = '';
        })
        .addCase(getBannerPop.pending, (state) => {
                    state.isLoading = true
                    //state.banneritems = []
                  })
                  .addCase(getBannerPop.fulfilled, (state, action) => {
                    state.isLoading = false
                    const items = action.payload.items.filter(x=>x.typename =='Popup').map((x,index)=>({...x, show:true,id:++index, photo:`https://100lankip2.com/images${x.photo}`}))
                    state.banneritems = items
                    //state.currentPhoto = items.find(item => item.show) || null;
                    if(items.length > 0) {

                                  // แสดงรูปแรกเมื่อโหลดสำเร็จ ถ้าผ่านเงื่อนไขเวลา
                    state.currentPhoto = viewingUtils.canShowSlides() ? 
                    items.find(item => item.show) || null : null;
                    
                      state.showphoto = true
                    }
                  })
      }
})

// Actions
export const {setTotal, updateCart, setCurrentPhoto, showNextPhoto, togglePhoto, clearCart, setType, setNumber, sumTotal, clearNoAll, delCart} = tunsamaiSlice.actions;
export default tunsamaiSlice.reducer
