import PageHeader from "../../components/PageHeader";
import { useEffect } from "react";
import {numberWithCommas} from "../../utils/math";
import {useSelector, useDispatch} from "react-redux";
import {useState} from "react";
import { createqrcode, getbalance } from '../../store/user'
import {useNavigate} from "react-router-dom";
//import { useEffect } from "react";
import ModelApprove from '../../components/ModalQr'
import NavBar from '../../components/NavBar'
import Modal from '../../components/Modal'
import { Spinner } from "react-bootstrap";

  const circleButtonStyle2 = {
    width: '32px',
    height: '32px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '3px',
    border: 'none',
    transition: 'transform 0.2s',
   
  };
function TunsamaiSummary() {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cart} = useSelector(state => state.hk);
    const total = cart.reduce((total, item) => total + item.min,0) || 0
    
    const { gameitem, isLoadingLogin } = useSelector(state => state.user);
    console.log('cartxxx', cart)
    console.log('total', total)
    console.log('gameitem', gameitem)
    const [payments] = useState([
        {id: 1, image: "/images/icon/credit.png", name: "Credit"},
       // {id: 2, image: "/images/icon/one.png", name: "BCEL One"},
       // {id: 3, image: "/images/icon/umoney.png", name: "U-money"}
    ])

    const {item} = useSelector(state => state.user);
    useEffect(()=> {
        dispatch(getbalance({}))
    },[])
    const [show, setShow] = useState(false);
    const [pay, setPay] = useState('')

    const showqr = (id) => {
        setPay(id)
        let bank = 1
        if(id === 1) {
            bank = 'credit'
        }
        if(id === 2) {
            bank = 'bcel'
        }
        if(id === 3) {
            bank = 'umoney'
        }
        if(isLoadingLogin === false) {
            const checktype = (type, typename) => {
                if(type.length === 1) {
                    return '1'
                } else if(typename === '2lower') {
                    return '2lower'
                } else if(type.length === 2) {
                    return '2'
                } else if(type.length === 3) {
                    return '3'
                } else if(type.length === 4) {
                    return '4'
                } else if(type.length === 5) {
                    return '5'
                } else if(type.length === 6) {
                    return '6'
                }
            }
            const newitems = cart.map(x=> ({...x,number:x.no}))
            if(item?.balance < total && id === 1) {
                setShow(true)
            } else {
                dispatch(createqrcode({amount:total, cart:newitems, huaysubId:gameitem?.id, bankdata:bank, navigate}))
            }
            
        }
       
    }

    return (
        <div className={'container withdraw'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}}>

                    {/* page title */}
                    <PageHeader logo logoSize={'80'} backPath={'/lotto/hongkong'}/>
                    {/* end page title */}

                    {/* cart */}
                    <div className={'mb-5'} data-aos="fade-up">
                        <div className={'text-primary h5'}>
                            รายการซื้อ
                        </div>

                        <div className={'card card-green mb-5'}>
                            <div className={'card-body'}>

                                {/* cart */}
                                {cart.length > 0 && <div className={'cart mb-3'}>
                                    <div className={'d-flex justify-content-between text-secondary mb-1'}>
                                        <div>ลำดับ</div>
                                        <div>หมายเลข</div>
                                        <div>จำนวนเงิน</div>
                                    </div>

                                    {cart.map((x, index) => {
                                        return <div className={'list'} key={`${x.name}_${index}`}>
                                            <div className={'d-flex justify-content-between align-items-end mb-2'}>
                                                
                                                <div className={'col-auto'}>
                                                    {++index}.
                                                </div>
                                                
                                                {
                                x.type !== '2lower' &&  <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(0, 2)}
                                </div>
                              </div>
                              }
 {
                                x.type == '2lower' &&  <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  background:'transparent',
                                  color: 'white'
                                }}>
                                  
                                </div>
                              </div>
                              }
                           {
                               x.type == '2lower' &&  <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#000',
                                  color: 'white'
                                }}>
                                  +
                                </div>
                              </div>
                              }
                              {
                                x.type == '4outside'  &&  <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#000',
                                  color: 'white'
                                }}>
                                  +
                                </div>
                              </div>
                              }
                              {
                                x.type !== '2lower' && <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(2, 4)}
                                </div>
                              </div>
                              }

                              {
                                x.type == '2lower' && <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(0, 2)}
                                </div>
                              </div>
                              }
                              
                              {
                                x.type == '4inside' &&  <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#212529',
                                  color: 'white'
                                }}>
                                  
                                </div>
                              </div>
                              }
                              {
                                x.lg == 6 && <div className="col-auto">
                                <div style={{
                                  ...circleButtonStyle2,
                                  backgroundColor: '#ffc107',
                                  color: 'white'
                                }}>
                                  {x.no.substring(4, 6)}
                                </div>
                              </div>
                              }

                                                <div className={'col-auto align-items-end text-end bg1009'}>
                                                <span
                                                    className={'me-1'}>  {numberWithCommas(x.amount)}</span>
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>}
                                {/* end cart */}

                                <div className={'d-flex justify-content-between align-items-end mb-2'}>
                                    <div className={'text-secondary'}>
                                        ยอดซื้อทั้งหมด
                                    </div>
                                    <div className={'h5 mb-0 lh-1 text-primary text-white'}>
                                        {numberWithCommas(total)}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    {/* end cart */}

                    {/* payments  */}
                    <div className={'mb-5'}>
                        <div className={'text-primary h5'}>
                            เลือกธนาคาร
                        </div>

                        {payments.map(data => {
                            return <div className={'card card-green mb-3'} key={`${data.id}`}>
                                <div className={'py-2 px-3'}>
                                    <div className={'d-flex gap-3 justify-content-between align-items-center'}>
                                        <div className={'d-flex gap-3 align-items-center'}>
                                            <img className="img-fluid img-payment"
                                                 src={`${process.env.PUBLIC_URL}${data.image}`}
                                                 alt={data.name}/>
                                            <div>
                                                <div className={'text-secondary'}>
                                                    จ่ายผ่าน
                                                </div>
                                                <div className={'text-primary'}>
                                                    {data.id !== 1 && data.name}
                                                    {
                                                        data.id === 1 && <span className="text-white">กระเป๋าเงิน : {numberWithCommas(item?.balance || 0)}</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className={'text-end'}>
                                            {
                                                isLoadingLogin === true && pay === data.id ? <button className={'btn btn-primary text-decoration-none'}  ><Spinner /></button> : <button className={'btn btn-primary text-decoration-none'} onClick={()=>showqr(data.id)} >ยืนยัน</button>
                                            }
                                            
                                            {
                                                /*
<NavLink to={'/lotto/tunsamai/payment'}
                                                     className={'btn btn-primary text-decoration-none'}>
                                                เลือก
                                            </NavLink>
                                                */
                                            }
                                
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                        })}
                    </div>
                    {/* end payments  */}
                </div>
            </div>
            <ModelApprove show={false} onHide={()=>{}} />
            <NavBar />
            <Modal show={show} msg='ยอดเงินของท่านไม่เพียงพอ.' onHide={()=>setShow(false)} />
        </div>
    );
}

export default TunsamaiSummary;
