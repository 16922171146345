import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import * as _ from 'underscore'
import {addToCart} from "../../store/tunsamai";
import TunsamaiMenu from "../../components/TunsamaiMenu";
import PageHeader from "../../components/PageHeaderR";
import BoxRandom from "./BoxRandom"

function TunsamaiSelect() {
    const dispatch = useDispatch();
    const {list} = useSelector(state => state.tunsamai);

    const [selected, setSelected] = useState([]);
    const select = (data) => {
        let {id, number, image} = data;

        let obj = _.findWhere(selected, {id: id});
        if (obj) {
            if (obj.result.includes(number)) {
                // remove
                obj.result = _.without(obj.result, number);
                setSelected([...selected])

                // delete obj when null
                if (obj.result.length === 0) {
                    setSelected(_.without(selected, _.findWhere(selected, {
                        id: id
                    })))
                }
            } else {
                // add
                obj.result = [...obj.result, number];
                setSelected([...selected])
            }
        } else {
            // create
            setSelected(prevState => ([...prevState, {id: id, image: image, result: [number]}]))
        }
    }

    const selectAll = (data) => {
        let {id, numbers, image} = data;
        let obj = _.findWhere(selected, {id: id});
        if (obj) {
            let exist = _.every(numbers, function (number) {
                return obj.result.includes(number)
            });

            if (exist) {
                // delete obj
                setSelected(_.without(selected, _.findWhere(selected, {
                    id: id
                })));
            } else {
                // add all
                obj.result = _.union(obj.result, numbers);
                setSelected([...selected])
            }
        } else {
            // create
            setSelected(prevState => ([...prevState, {id: id, image: image, result: [...numbers]}]));
        }
    }

    const isActiveNumber = (data) => {
        let {id, number} = data;
        let obj = _.findWhere(selected, {id: id});
        if (obj) {
            if (obj.result.includes(number)) {
                // is active
                return 'active'
            }
        } else {
            // not active
            return ''
        }
    }

    const add = ({amount}) => {
        console.log('selected', selected)
        dispatch(addToCart({list: selected, amount: amount}));
        setSelected([]);
    }

    return (
        <div className={'container tumra-container'}>
            <div className={'row justify-content-center min-vh-100 py-4'}>
                <div className={'col-12 col-md-auto w-100'} style={{maxWidth: '450px'}} data-aos="fade-up">

                    {/* page title */}
                    <PageHeader logo backPath={'/lotto/tunsamai'}/>
                    {/* end page title */}

                    {/* list */}
                  
                     <BoxRandom />
                      
                    {/* end list */}
                </div>
            </div>

            <TunsamaiMenu addToCart={add}/>
        </div>
    );
}

export default TunsamaiSelect;
